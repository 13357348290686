<template>
  <div>
    <div class="tw-grid tw-grid-cols-6 tw-items-center">
      <div class="sm:tw-col-span-4 tw-col-span-6">
        <div class="tituloContent sm:tw-text-left tw-text-center">Totens</div>
      </div>
      <div class="sm:tw-col-span-2 sm:tw-p-0 tw-p-4 tw-col-span-6">
        <div class="sm:tw-text-right tw-text-center">
          <button
            class="tw-text-white tw-py-2 tw-px-5 tw-rounded-md tw-mr-0 sm:tw-mr-4 tw-bg-pink-600 hover:tw-bg-pink-500 tw-transition-colors tw-duration-500"
            @click="novoCadastroTotem()"
            v-if="getUserRole == 'ADMIN'"
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
    <div class="tw-px-5 tw-mx-0">
      <div class="tw-bg-gray-100 tw-flex tw-align-middle tw-rounded-md">
        <div class="tw-items-center tw-p-3">
          <v-icon> mdi-magnify </v-icon>
        </div>
        <input
          type="text"
          v-model="searchName"
          placeholder="Buscar nome do totem"
          class="tw-p-3 tw-w-full tw-outline-none tw-rounded-tr-md tw-rounded-br-md focus:tw-bg-gray-50"
        />
      </div>
    </div>
    <div class="tw-p-5 tw-flex tw-flex-wrap">
      <div
        :key="index"
        v-for="(totem, index) in getList"
        class="tw-m-2 tw-w-full md:tw-w-80"
      >
        <div
          class="tw-bg-white tw-shadow-md tw-inline-block tw-min-h-48 tw-w-full sm:tw-w-80 tw-text-center tw-rounded-md"
        >
          <div
            class="tw-font-semibold tw-tracking-wide tw-text-sm tw-text-gray-600 tw-py-5"
          >
            {{ totem.nome }}
          </div>
          <div class="tw-italic tw-text-gray-400 tw-my-1 tw-text-sm">
            {{ totem.descricao }}
          </div>

          <div
            class="tw-bg-gray-50 tw-px-3 tw-rounded-b-md tw-py-4 tw-mt-10 tw-flex tw-justify-items-end tw-justify-end tw-text-right"
          >
            <div
              class="tw-m-1"
              v-if="$store.getters['user/getRole'] == 'ADMIN'"
            >
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
                @click="abrirUsuariosTotem(totem.id)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
                  >mdi-account-multiple</v-icon
                >
              </button>
            </div>
            <div class="tw-m-1">
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
                @click="abrirModalImpressoes(totem.id)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
                  >mdi-printer-search</v-icon
                >
              </button>
            </div>
            <div class="tw-m-1">
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
                @click="abrirModalEndereco(totem)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
                  >mdi-home</v-icon
                >
              </button>
            </div>
            <div class="tw-m-1">
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
                @click="abrirComprasDoTotem(totem)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
                  >mdi-currency-usd</v-icon
                >
              </button>
            </div>
            <div class="tw-m-1">
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
                @click="abrirConfigDoTotem(totem)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
                  >mdi-cog</v-icon
                >
              </button>
            </div>
            <div class="tw-m-1" v-if="getUserRole == 'ADMIN'">
              <button
                class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-red-100 tw-fill-current tw-group tw-text-white"
                @click="abrirModalDelete(totem)"
              >
                <v-icon
                  class="tw-text-gray-600 tw-fill-current tw-stroke-current group-hover:tw-text-red-700"
                  >mdi-delete</v-icon
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vEndereco
      v-if="modalEndereco"
      @close="closeModalEndereco"
      :totem="totemSelecionado"
    />
    <vDetalhesImpressoes
      v-if="modalImpressoes"
      @close="closeModalImpressoes"
      :totemID="totemIdSelecionado"
    />
    <vModalConfirmDelete
      :totem="totemSelecionado"
      v-if="modalDelete"
      @close="closeModalDelete"
    />
  </div>
</template>

<script>
import vEndereco from "./detalhesEndereco.vue";
import vDetalhesImpressoes from "./detalhesImpressoes/index.vue";
import vModalConfirmDelete from "./vModalConfirmDelete.vue";
import { insertContadoresFirebase } from "./devIncludeDadosTotem";

export default {
  components: {
    vEndereco,
    vDetalhesImpressoes,
    vModalConfirmDelete,
  },
  data() {
    return {
      modalDelete: false,
      modalEndereco: false,
      modalImpressoes: false,
      totemSelecionado: {},
      totemIdSelecionado: "",
      searchName: "",
    };
  },
  mounted() {
    this.$store.dispatch("dashboard/loadTotens");
  },
  methods: {
    abrirUsuariosTotem(id) {
      this.$router.push(`/dashboard/totem/${id}/franqueados`);
    },
    includeDados(totem) {
      insertContadoresFirebase(totem.id);
    },
    closeModalDelete() {
      this.totemSelecionado = {};
      this.modalDelete = false;
    },
    abrirModalDelete(totem) {
      this.totemSelecionado = totem;
      this.modalDelete = true;
    },
    abrirConfigDoTotem(totem) {
      this.$router.push(`/dashboard/totem/${totem.id}/config`);
    },
    abrirComprasDoTotem(totem) {
      this.$router.push(`/dashboard/totem/${totem.id}/compras`);
    },
    closeModalImpressoes() {
      this.modalImpressoes = false;
      this.totemSelecionado = {};
    },
    closeModalEndereco() {
      this.modalEndereco = false;
      this.totemSelecionado = {};
    },
    abrirModalImpressoes(totemID) {
      this.totemIdSelecionado = totemID;
      this.modalImpressoes = true;
    },
    abrirModalEndereco(totem) {
      this.totemSelecionado = { ...totem };
      this.modalEndereco = true;
    },
    novoCadastroTotem() {
      this.$router.push(`/dashboard/totem/cadastro-totem`);
    },
  },
  computed: {
    getUserRole() {
      return this.$store.getters["user/getRole"];
    },
    getList() {
      return this.$store.getters["dashboard/getTotens"].filter((item) => {
        let nameList = item.nome?.toUpperCase();
        let searchName = this.searchName?.toUpperCase();
        return nameList.match(searchName) && !item.excluido;
      });
    },
  },
};
</script>
