<template>
  <div
    class="tw-z-50 tw-bg-black tw-bg-opacity-50 tw-top-0 tw-left-0 tw-fixed tw-w-full tw-h-full tw-justify-center tw-items-center tw-flex tw-p-2"
  >
    <div
      class="tw-p-4 tw-shadow-md tw-rounded-xl tw-bg-white tw-w-full tw-mx-2 sm:tw-mx-auto md:tw-w-4/6"
    >
      <div
        class="tw-m-3 tw-text-center tw-font-bold tw-border-b tw-border-solid tw-border-gray-200 tw-pb-3 tw-relative tw-grid tw-grid-cols-3 tw-items-center"
      >
        <div class="tw-col-span-2 tw-text-left">Informações de impressão</div>
        <div class="tw-col-span-1 tw-text-right">
          <button
            class="tw-rounded-full tw-p-2 tw-duration-500 tw-transition-colors hover:tw-bg-gray-200"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </div>
      </div>
      <vEditPaperImpress
        @close="editPaper = false"
        v-if="editPaper"
        :totem="getTotem"
      />
      <div v-if="!editPaper" class="listaContent">
        <ListaDeItens titulo="Impressora Polaroid">
          <ItemDaLista
            titulo="Rolo atual"
            icone="mdi-file-multiple"
            :amount="getTotem.contadores.countPolaroid"
            :totemID="getTotem.id"
            :reset="{ nome: 'countPolaroid', quantidade: 700 }"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Impressões feitas"
            :totemID="getTotem.id"
            :amount="getTotem.contadores.countPolaroidTotal"
            icone="mdi-printer"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Ribbon Atual"
            :amount="getTotem.contadores.countPolaroidRibbon"
            :totemID="getTotem.id"
            icone="mdi-film"
            :reset="{ nome: 'countPolaroidRibbon', quantidade: 700 }"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Ribbon usados"
            :amount="getTotem.contadores.countPolaroidRibbonTotal"
            :totemID="getTotem.id"
            icone="mdi-film"
          ></ItemDaLista>
        </ListaDeItens>
        <ListaDeItens titulo="Impressora Normal">
          <ItemDaLista
            titulo="Rolo atual"
            icone="mdi-file-multiple"
            :amount="getTotem.contadores.countNormal"
            :totemID="totemID"
            :reset="{ nome: 'countNormal', quantidade: 700 }"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Impressões feitas"
            :amount="getTotem.contadores.countNormalTotal"
            :totemID="getTotem.id"
            icone="mdi-printer"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Ribbon Atual"
            :amount="getTotem.contadores.countNormalRibbon"
            :totemID="getTotem.id"
            icone="mdi-film"
            :reset="{ nome: 'countNormalRibbon', quantidade: 700 }"
          ></ItemDaLista>
          <ItemDaLista
            titulo="Ribbon usados"
            :totemID="getTotem.id"
            :amount="getTotem.contadores.countNormalRibbonTotal"
            icone="mdi-film"
          ></ItemDaLista>
        </ListaDeItens>
        <div class="tw-px-2 tw-text-right" v-if="editPaper == false">
          <button
            class="tw-p-2 tw-px-5 hover:tw-bg-pink-100 tw-shadow-xs tw-bg-white tw-rounded-sm tw-outline-none tw-font-bold tw-text-pink-500 tw-transition-colors tw-duration-500"
            @click="editPaper = true"
          >
            Editar folhas
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemDaLista from "./item";
import ListaDeItens from "./list-item.vue";
import vEditPaperImpress from "./editorPaper.vue";

export default {
  props: {
    totem: {
      require: true,
      type: Object,
    },
    totemID: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      editPaper: false,
    };
  },
  computed: {
    getTotem() {
      return this.$store.getters["dashboard/getTotemId"](this.totemID);
    },
  },
  components: {
    ItemDaLista,
    ListaDeItens,
    vEditPaperImpress,
  },
};
</script>

<style lang="scss" scoped>
.listaContent {
  overflow-y: auto;
  max-height: 70vh;
}
</style>
