<template>
  <div
    class="tw-z-50 tw-bg-black tw-bg-opacity-50 tw-top-0 tw-left-0 tw-fixed tw-w-full tw-h-full tw-justify-center tw-items-center tw-flex"
  >
    <!-- sm:tw-mx-auto sm:tw-w-2/4 -->
    <div
      class="tw-py-4 tw-rounded-xl tw-bg-white tw-w-full tw-mx-1 tw-max-w-lg"
    >
      <div
        class="tw-grid tw-grid-cols-3 tw-items-center tw-mb-5 tw-pb-3 tw-px-4 tw-border-b tw-border-gray-200 tw-border-solid"
      >
        <div class="tw-pl-2 tw-text-xl tw-p-0 tw-col-span-2">Endereço</div>
        <div class="tw-col-span-1 tw-text-right">
          <button
            @click="$emit('close')"
            class="tw-border-2 tw-border-solid tw-border-gray-300 tw-rounded-full tw-group tw-w-9 tw-h-9 tw-transition-all tw-duration-200 hover:tw-border-red-300"
          >
            <v-icon
              size="22"
              class="tw-fill-current group-hover:tw-text-red-700 tw-transition-all tw-duration-200"
              >mdi-close</v-icon
            >
          </button>
        </div>
      </div>

      <div
        v-if="!modoEditar"
        id="viewDados"
        class="tw-break-all tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-text-center tw-px-4"
      >
        <div class="tw-col-span-1 tw-py-1">
          <div class="tw-font-normal">Cidade</div>
          <div class="tw-text-pink-500 tw-font-bold">
            {{ totem.endereco.cidade }}
          </div>
        </div>
        <div class="tw-col-span-1 tw-py-1">
          <div class="tw-font-normal">Estado</div>
          <div class="tw-text-pink-500 tw-font-bold">
            {{ totem.endereco.estado }}
          </div>
        </div>
        <div class="tw-col-span-1 tw-py-1">
          <div class="tw-font-normal">CEP</div>
          <div class="tw-text-pink-500 tw-font-bold">
            {{ totem.endereco.cep }}
          </div>
        </div>

        <div class="tw-col-span-1 tw-py-1">
          <div class="tw-font-normal">Logradouro</div>
          <div class="tw-text-pink-500 tw-font-bold">
            {{ totem.endereco.logradouro }}
          </div>
        </div>

        <div class="tw-col-span-1 tw-py-1">
          <div class="tw-font-normal">Bairro</div>
          <div class="tw-text-pink-500 tw-font-bold">
            {{ totem.endereco.bairro }}
          </div>
        </div>
        <div class="tw-col-span-1 sm:tw-col-span-2 tw-py-1 tw-mt-3">
          <button
            class="tw-rounded tw-px-3 tw-py-2 tw-bg-primary tw-text-white"
            @click="openEditor"
          >
            Editar endereço
          </button>
        </div>
      </div>
      <div v-else id="editDados" class="tw-px-6">
        <div class="tw-py-1 tw-flex tw-items-start tw-space-x-2">
          <div class="tw-flex-1 tw-pt-2">
            <v-text-field
              v-model="$v.endereco.cep.$model"
              name="name"
              label="CEP"
              id="id"
              dense
              v-mask="'#####-###'"
              :error="$v.endereco.cep.$invalid && $v.endereco.cep.$dirty"
            />
          </div>
          <div class="tw-flex-none">
            <v-btn color="primary" outlined @click="buscarCEP"> Buscar </v-btn>
          </div>
        </div>
        <div class="tw-py-1">
          <v-text-field
            v-model="$v.endereco.estado.$model"
            label="Estado"
            id="id"
            dense
            :error="$v.endereco.estado.$invalid && $v.endereco.estado.$dirty"
          />
        </div>
        <div class="tw-py-1">
          <v-text-field
            v-model="$v.endereco.cidade.$model"
            label="Cidade"
            id="id"
            dense
            :error="$v.endereco.cidade.$invalid && $v.endereco.cidade.$dirty"
          />
        </div>
        <div class="tw-py-1">
          <v-text-field
            v-model="$v.endereco.bairro.$model"
            name="bairro"
            label="Bairro"
            id="id"
            dense
            :error="$v.endereco.bairro.$invalid && $v.endereco.bairro.$dirty"
          />
        </div>
        <div class="tw-py-1">
          <v-text-field
            v-model="$v.endereco.logradouro.$model"
            name="logradouro"
            label="Logradouro"
            id="id"
            dense
            :error="
              $v.endereco.logradouro.$invalid && $v.endereco.logradouro.$dirty
            "
          />
        </div>
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-2">
          <div class="tw-text-center sm:tw-text-left tw-order-2 sm:tw-order-1">
            <v-btn
              :loading="loading"
              large
              color="grey darken-2"
              text
              class="tw-w-full"
              @click="fecharEditor()"
            >
              cancelar
            </v-btn>
          </div>
          <div class="tw-text-center sm:tw-text-right tw-order-1 sm:tw-order-2">
            <v-btn
              :loading="loading"
              large
              depressed
              class="tw-w-full"
              color="primary"
              :disabled="$v.endereco.$invalid"
              @click="salvarDados()"
            >
              salvar dados
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import serviceCEP from "@/service/cep";
import { updateTotem } from "../../../../service/firebase";

export default {
  props: {
    totem: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      endereco: {},
      loading: false,
      modoEditar: false,
    };
  },
  validations() {
    return {
      endereco: {
        cep: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
        },
        cidade: {
          required,
          minLength: minLength(3),
        },
        estado: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(9),
        },
        bairro: {
          required,
          minLength: minLength(3),
        },
        logradouro: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },
  mounted() {
    this.updateDadosProps();
  },
  methods: {
    updateDadosProps() {
      if (this.totem.endereco) {
        this.endereco = { ...this.totem.endereco };
      } else {
        this.endereco = {
          cep: "",
          cidade: "",
          estado: "",
          logradouro: "",
          bairro: "",
        };
      }
    },
    buscarCEP() {
      let payloadCEP = this.endereco.cep;
      serviceCEP
        .getEndereco(payloadCEP)
        .then((resp) => {
          console.log(resp);
          if (resp.data.erro) {
            this.$root.errorModal("CEP NÃO ENCONTRADO");
          } else {
            this.endereco.cidade = resp.data.localidade;
            this.endereco.estado = resp.data.uf;
            this.endereco.logradouro = resp.data.logradouro;
            this.endereco.bairro = resp.data.bairro;
          }
        })
        .catch((error) => {
          console.error(error);
          this.$root.errorModal("CEP NÃO ENCONTRADO");
        });
    },
    async salvarDados() {
      this.loading = true;
      let enderecoPayload = this.endereco;
      let response = await updateTotem(this.totem.id, {
        endereco: {
          cep: enderecoPayload.cep,
          bairro: enderecoPayload.bairro,
          cidade: enderecoPayload.cidade,
          estado: enderecoPayload.estado,
          logradouro: enderecoPayload.logradouro,
        },
      });
      if (response.ok) {
        this.$store.commit("setModal", {
          tipo: "SUCCESS",
          mensagem: "Alterado com sucesso!",
          toggle: true,
        });
        await this.$store.dispatch("dashboard/loadTotens");
        this.updateDadosProps();
        this.fecharEditor();
        this.$emit("close");
      } else {
        console.error(response.mensagem);
        this.$store.commit("setModal", {
          tipo: "ERROR",
          mensagem: "Ocorreu algum erro",
          toggle: true,
        });
        this.$emit("close");
      }
      this.loading = false;
    },
    fecharEditor() {
      this.modoEditar = false;
    },
    openEditor() {
      this.endereco = { ...this.totem.endereco };
      this.modoEditar = true;
    },
  },
};
</script>
