<template>
  <div>
    <div class="tw-px-3 tw-pt-2 tw-text-sm tw-text-gray-500 tw-text-center">
      Por padrão o rolo de reposição contém 700 folhas de impressão
    </div>
    <div class="tw-bg-yellow-100 tw-p-5 tw-rounded tw-text-yellow-600 my-2">
      Ao modificar essa área, irá modificar a contagem atual de folhas do totem
    </div>
    <div class="tw-p-3 tw-grid tw-grid-cols-2">
      <div class="tw-p-1">
        <v-text-field
          label="Folhas da Impressora Polaroid"
          type="number"
          v-model.number="include.contadores.countPolaroid"
          hint="recomendado 700"
        ></v-text-field>
      </div>
      <div class="tw-p-1">
        <v-text-field
          label="Folhas da Impressora Normal"
          type="number"
          v-model.number="include.contadores.countNormal"
          hint="recomendado 700"
        ></v-text-field>
      </div>
      <div class="tw-p-1">
        <v-text-field
          label="Ribbon Polaroid"
          type="number"
          v-model.number="include.contadores.countPolaroidRibbon"
          hint="recomendado 700"
        ></v-text-field>
      </div>
      <div class="tw-p-1">
        <v-text-field
          label="Ribbon Normal"
          type="number"
          v-model.number="include.contadores.countNormalRibbon"
          hint="recomendado 700"
        ></v-text-field>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-text-center tw-pt-4">
      <div>
        <button class="btnText cancelar" @click="$emit('close')">
          Cancelar
        </button>
      </div>
      <div>
        <button
          class="tw-bg-pink-600 tw-transition-colors tw-duration-200 tw-w-4/5 sm:tw-w-3/5 hover:tw-bg-pink-500 tw-p-3 tw-rounded-md tw-text-white"
          @click="save()"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, setDoc } from "@firebase/firestore";
import { db } from "@/service/firebase";
export default {
  props: {
    totem: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      include: {
        contadores: {
          countPolaroid: 0,
          countPolaroidTotal: 0,
          countPolaroidRibbon: 0,
          countPolaroidRibbonTotal: 0,
          countNormal: 0,
          countNormalTotal: 0,
          countNormalRibbon: 0,
          countNormalRibbonTotal: 0,
        },
      },
    };
  },
  mounted() {
    const countValue = this.totem.contadores;
    this.include.contadores = {
      countPolaroid: countValue.countPolaroid ? countValue.countPolaroid : 0,
      countPolaroidTotal: countValue.countPolaroidTotal
        ? countValue.countPolaroidTotal
        : 0,
      countPolaroidRibbon: countValue.countPolaroidRibbon
        ? countValue.countPolaroidRibbon
        : 0,
      countPolaroidRibbonTotal: countValue.countPolaroidRibbonTotal
        ? countValue.countPolaroidRibbonTotal
        : 0,
      countNormal: countValue.countNormal ? countValue.countNormal : 0,
      countNormalTotal: countValue.countNormalTotal
        ? countValue.countNormalTotal
        : 0,
      countNormalRibbon: countValue.countNormalRibbon
        ? countValue.countNormalRibbon
        : 0,
      countNormalRibbonTotal: countValue.countNormalRibbonTotal
        ? countValue.countNormalRibbonTotal
        : 0,
    };
  },
  methods: {
    async save() {
      const totemID = this.totem.id;
      const ref = doc(db, "totem", totemID);
      await setDoc(
        ref,
        {
          contadores: {
            countPolaroid: Number(this.include.contadores.countPolaroid),
            countPolaroidRibbon: Number(
              this.include.contadores.countPolaroidRibbon
            ),
            countNormal: Number(this.include.contadores.countNormal),
            countNormalRibbon: Number(
              this.include.contadores.countNormalRibbon
            ),
          },
        },
        { merge: true }
      ).then(() => {
        this.$root.sucessoModal("Salvo com sucesso");
        this.$emit("close");
      });
    },
  },
};
</script>
