import { db } from "@/service/firebase";
import { setDoc, doc } from "firebase/firestore";

export async function insertContadoresFirebase(token) {
  if (!token) {
    throw Error("ID TOTEM não definido!");
  }
  let docRef = doc(db, `totem/${token}`);
  await setDoc(
    docRef,
    {
      config: {
        manutencao: false,
      },
    },
    { merge: true }
  );
}

/*
,
    contadores: {
      countNormal: 0,
      countNormalTotal: 0,
      countPolaroid: 0,
      countPolaroidTotal: 0,
    },
*/
