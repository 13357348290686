<template>
  <div
    class="tw-h-screen tw-w-screen tw-fixed tw-top-0 tw-left-0 tw-bg-black tw-bg-opacity-30 tw-flex tw-items-center"
  >
    <div class="tw-flex-1">
      <div
        class="tw-bg-white tw-p-4 tw-rounded-lg tw-w-2/3 sm:tw-w-3/6 tw-mx-auto tw-shadow-xl"
      >
        <div class="tw-text-xl tw-font-bold tw-m-3">Atenção</div>
        <div class="tw-mx-3">
          <div>
            Para confirmar a exclusão digite
            <i class="tw-font-bold">excluir permanentemente</i>
          </div>
          <div class="tw-my-3">
            <input
              class="tw-p-3 tw-bg-gray-100 tw-w-full tw-rounded-lg tw-font-bold"
              type="text"
              placeholder="Digite aqui"
              v-model="textInput"
            />
          </div>
        </div>
        <div
          class="tw-grid tw-text-center md:tw-grid-cols-2 tw-grid-cols-1 tw-pt-6 tw-px-3 tw-pb-3"
        >
          <div class="md:tw-text-left">
            <button
              class="btnCustom tw-font-bold tw-text-gray-700 tw-bg-gray-100 tw-bg-opacity-50 hover:tw-bg-gray-200"
              @click="$emit('close')"
            >
              cancelar
            </button>
          </div>
          <div class="md:tw-text-right md:mt-0 mt-5">
            <button
              :disabled="!togglePositive"
              class="btnCustom tw-text-white tw-font-bold"
              :class="{
                'tw-bg-red-500': togglePositive,
                'tw-bg-gray-300 tw-cursor-not-allowed hover:tw-bg-gray':
                  !togglePositive,
              }"
              @click="confirmaExclude()"
            >
              confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/service/firebase.js";

export default {
  props: {
    totem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      textSeguro: "excluir permanentemente",
      textInput: "",
    };
  },
  methods: {
    async confirmaExclude() {
      if (!this.togglePositive) return false;
      const totemID = this.totem.id;
      const totemRef = doc(db, "totem", `${totemID}`);
      await updateDoc(totemRef, {
        excluido: true,
        excluidoUser: "",
        excluidoData: "",
      }).then(async () => {
        await this.$store.dispatch("dashboard/loadTotens");
        this.$emit("close");
      });
    },
  },
  computed: {
    togglePositive() {
      let comparativo = this.textInput == this.textSeguro;
      return comparativo;
    },
  },
};
</script>

<style lang="scss" scoped>
.btnCustom {
  @apply tw-text-sm tw-cursor-pointer tw-w-full md:tw-w-1/2 tw-border-none tw-p-2 tw-py-3 tw-rounded-md tw-uppercase;
}
</style>
