<template>
  <div class="tw-p-3 gridCustom tw-items-center">
    <div
      class="tw-flex tw-bg-white tw-rounded-lg tw-shadow tw-p-0 tw-w-14 tw-h-14 tw-justify-center tw-items-center tw-border tw-border-solid tw-border-gray-200"
    >
      <v-icon class="tw-stroke-current tw-fill-current tw-text-gray-600">{{
        icone
      }}</v-icon>
    </div>
    <div class="tw-pl-4">
      <div class="tw-mt-1 tw-text-sm tw-font-bold tw-text-gray-600">
        {{ titulo }}
      </div>
      <div class="tw-text-sm tw-font-bold tw-text-gray-400">
        {{ amount | noDefined }}
      </div>
    </div>
    <div></div>
    <div v-if="reset" class="tw-pl-4">
      <button
        class="tw-text-sm tw-p-1 tw-px-2 tw-rounded-sm tw-bg-pink-100 tw-text-pink-600"
        @click="resetRoll()"
      >
        resetar para 700
      </button>
    </div>
  </div>
</template>

<script>
import { setDoc, doc } from "@firebase/firestore";
import { db } from "@/service/firebase";
export default {
  props: {
    icone: {
      type: String,
      require: true,
    },
    titulo: {
      type: String,
      require: true,
    },
    amount: {
      type: Number,
      require: true,
    },
    totemID: {
      type: String,
      require: false,
    },
    reset: {
      type: Object,
      require: false,
    },
  },
  filters: {
    noDefined: function (val) {
      return val ? val : "Não definido";
    },
  },
  methods: {
    async resetRoll() {
      if (this.reset) {
        const referenciaTotem = doc(db, "totem", this.totemID);
        const namePath = this.reset.nome;
        const valuePath = 700; // default
        console.log("resetRoll", namePath, valuePath);
        await setDoc(
          referenciaTotem,
          {
            contadores: {
              [namePath]: valuePath,
            },
          },
          { merge: true }
        ).then(() => {
          this.$root.sucessoModal("Quantidade resetada");
        });
      } else {
        this.$root.errorModal("Parametro inválido");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gridCustom {
  display: grid;
  grid-template-columns: auto 1fr;
}
</style>
