<template>
  <div class="">
    <div class="tw-text-gray-500 tw-font-normal tw-text-md tw-p-3 tw-pb-1">
      {{ titulo }}
    </div>
    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      require: true,
    },
  },
};
</script>

<style></style>
